import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useForm } from "react-final-form";
import styled from "styled-components";

import { StyledHeadline2, ComponentGroup } from "yuka";

import { Input, RadioGroup } from "forms/FormComponents";
import { required } from "utils/form/inputValidations";
import { useFormValue } from "utils/hooks/form";

import {
  INDIVIDUAL_SOURCES,
  ENTITY_SOURCES,
  TRUST_SOURCES,
  BUYER_AGREEMENT,
  SELLER_AGREEMENT,
  BUYER_FIRM_ORDER,
  SELLER_FIRM_ORDER,
  sourceProfileOptions,
  entityTypeOptions,
  US,
  SEC_REGISTER_ENTITY,
} from "./constants";

const StyledFollowupContainer = styled.div`
  margin-left: 32px;
`;

const SourceType = ({ onboardingRequest }) => {
  const { change } = useForm();
  const sourceType = useFormValue("sourceType");
  const regulatoryLocation = useFormValue("regulatoryLocation");
  const entityType = useFormValue("entityType");
  let buyingOrSelling = "client";

  useEffect(() => {
    if (sourceType !== ENTITY_SOURCES) {
      change("entityType", null);
      change("crdNumber", null);
    }
  }, [change, sourceType]);

  if (
    onboardingRequest.engagementType === BUYER_AGREEMENT ||
    onboardingRequest.engagementType === BUYER_FIRM_ORDER
  ) {
    buyingOrSelling = "buyer";
  } else if (
    onboardingRequest.engagementType === SELLER_AGREEMENT ||
    onboardingRequest.engagementType === SELLER_FIRM_ORDER
  ) {
    buyingOrSelling = "seller";
  }
  if (onboardingRequest.spvInvite) {
    buyingOrSelling = "investor";
  }
  return (
    <>
      <StyledHeadline2>{`Is the ${buyingOrSelling} an individual, entity, or trust?`}</StyledHeadline2>
      <RadioGroup items={sourceProfileOptions} name="sourceType" validate={[required]} />
      <StyledFollowupContainer>
        {sourceType === INDIVIDUAL_SOURCES && (
          <Input
            name="name"
            title="Full Name"
            placeholder="e.g. Bob Bradley"
            validate={[required]}
          />
        )}
        {(sourceType === ENTITY_SOURCES || sourceType === TRUST_SOURCES) && (
          <ComponentGroup>
            {sourceType === ENTITY_SOURCES && regulatoryLocation === US && (
              <>
                <StyledHeadline2>Is the entity registered with the SEC</StyledHeadline2>
                <RadioGroup items={entityTypeOptions} name="entityType" validate={[required]} />
              </>
            )}
            <Input
              name="entityName"
              title={sourceType === ENTITY_SOURCES ? "Entity Name" : "Trust Name"}
              placeholder="Example"
              validate={[required]}
            />
            <Input
              name="authorizedSignatoryName"
              title="Authorized Signatory's Full Name"
              placeholder="Example"
              validate={[required]}
            />
            {entityType === SEC_REGISTER_ENTITY && (
              <Input
                name="crdNumber"
                title="CRD #"
                placeholder="e.g. 123456"
                validate={[required]}
              />
            )}
          </ComponentGroup>
        )}
      </StyledFollowupContainer>
    </>
  );
};

SourceType.propTypes = {
  onboardingRequest: PropTypes.shape({
    engagementType: PropTypes.string,
    spvInvite: PropTypes.shape({
      apiId: PropTypes.string,
    }),
  }),
};

export default SourceType;
