import { BrowserRouter, Route, Routes } from "react-router-dom";
import { YukaThemeProvider } from "yuka";

import { QueryClientProvider } from "api";
import {
  NotFoundNoHomePage,
  ClientOnboardingCompletedPage,
  IraInvestorPage,
} from "templates/ErrorPages";
import StateProvider from "utils/StateProvider";

import ClientOnboardingView from "./ClientOnboardingView";

const DocumentsApp = () => (
  <YukaThemeProvider theme={{ componentVersion: 2, useTangeloTable: false }}>
    <QueryClientProvider>
      <StateProvider>
        <BrowserRouter>
          <Routes>
            <Route path=":id/" element={<ClientOnboardingView />} />
            <Route path="completed/" element={<ClientOnboardingCompletedPage />} />
            <Route path="ira/" element={<IraInvestorPage />} />
            <Route path="*" element={<NotFoundNoHomePage />} />
          </Routes>
        </BrowserRouter>
      </StateProvider>
    </QueryClientProvider>
  </YukaThemeProvider>
);

export default DocumentsApp;
