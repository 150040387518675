import React from "react";
import PropTypes from "prop-types";
import { useSearchParams } from "react-router-dom";

import { IconButton, ListItem, XCloseIcon } from "yuka";

import { useDelete, QUERY_KEYS } from "api";

const UploadedFileListItem = ({ onboardingRequestId, document }) => {
  const [searchParams] = useSearchParams();
  const { onSubmit: onDelete } = useDelete(
    QUERY_KEYS.ONBOARDING_DOCUMENTS.detail(onboardingRequestId, document.apiId, [], {
      token: searchParams.get("token"),
    })
  );
  return (
    <ListItem
      text={document.name}
      trailingContent={<IconButton onClick={onDelete} icon={XCloseIcon} />}
    />
  );
};

UploadedFileListItem.propTypes = {
  onboardingRequestId: PropTypes.string.isRequired,
  document: PropTypes.shape({
    name: PropTypes.string,
    apiId: PropTypes.string,
  }).isRequired,
};

export default UploadedFileListItem;
