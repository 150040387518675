import {
  BUYER_AGREEMENT,
  SELLER_AGREEMENT,
  BUYER_FIRM_ORDER,
  SELLER_FIRM_ORDER,
  INDIVIDUAL_SOURCES,
  ENTITY_SOURCES,
  TRUST_SOURCES,
  commissionDisplay,
} from "utils/constants";

const US = "US";
const EU = "EU";
const FOREIGN_OTHER = "Other";

const sourceProfileOptions = [
  { label: "Individual", value: INDIVIDUAL_SOURCES },
  { label: "Entity", value: ENTITY_SOURCES },
  { label: "Trust", value: TRUST_SOURCES },
];

const regulatoryLocationOptions = [
  { label: US, value: US },
  { label: EU, value: EU },
  { label: FOREIGN_OTHER, value: FOREIGN_OTHER },
];

const SEC_REGISTER_ENTITY = "SEC Registered Entity";
const NON_REGISTERED_US_LLC_OR_LP = "Non-registerd US LLC or LP";
const entityTypeOptions = [
  { label: "Yes", value: SEC_REGISTER_ENTITY },
  { label: "No", value: NON_REGISTERED_US_LLC_OR_LP },
];

export {
  INDIVIDUAL_SOURCES,
  ENTITY_SOURCES,
  TRUST_SOURCES,
  BUYER_AGREEMENT,
  SELLER_AGREEMENT,
  BUYER_FIRM_ORDER,
  SELLER_FIRM_ORDER,
  commissionDisplay,
  US,
  EU,
  FOREIGN_OTHER,
  sourceProfileOptions,
  regulatoryLocationOptions,
  entityTypeOptions,
  SEC_REGISTER_ENTITY,
  NON_REGISTERED_US_LLC_OR_LP,
};
