import React from "react";
import { useForm } from "react-final-form";
import styled from "styled-components";

import { StyledHeadline2, StyledCaption2 } from "yuka";

import { Input, InputRow, RadioGroup } from "forms/FormComponents";
import { required, requiredBoolean, isEmail } from "utils/form/inputValidations";
import { useFormValue } from "utils/hooks/form";

const StyledSignerHeadlineContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
  gap: 4px;
`;

const StyledAuthorizedSignatoryContainer = styled.div`
  margin-left: 32px;
`;

const authorizedSignerOptions = [
  { label: "Yes", value: true },
  { label: "No, I am only filing out the form(s) and someone else is the signatory", value: false },
];

const AuthorizedSigner = () => {
  const { change } = useForm();
  const authorizedSigner = useFormValue("authorizedSigner", null);
  const cleanAuthSignerFields = () => {
    if (authorizedSigner) {
      change("authorizedSignatoryName", null);
      change("authorizedSignatoryEmail", null);
    }
  };
  return (
    <>
      <StyledHeadline2>Are you an authorized signatory?</StyledHeadline2>
      <RadioGroup
        items={authorizedSignerOptions}
        name="authorizedSigner"
        validate={[requiredBoolean]}
        onChange={cleanAuthSignerFields}
      />
      {authorizedSigner === false && (
        <StyledAuthorizedSignatoryContainer>
          <StyledSignerHeadlineContainer>
            <StyledHeadline2>Please provide authorized signatory's information</StyledHeadline2>
            <StyledCaption2>
              Once you complete the onboarding form(s), they will be forwarded via DocuSign to the
              following authorized signatory for execution.
            </StyledCaption2>
          </StyledSignerHeadlineContainer>
          <InputRow>
            <Input
              name="authorizedSignatoryName"
              title="Authorized Signatory's Full Name"
              placeholder="Example"
              validate={[required]}
            />
          </InputRow>
          <InputRow>
            <Input
              name="authorizedSignatoryEmail"
              title="Authorized Signatory's Email"
              placeholder="Example"
              validate={[required, isEmail]}
            />
          </InputRow>
        </StyledAuthorizedSignatoryContainer>
      )}
    </>
  );
};

export default AuthorizedSigner;
