import React from "react";
import PropTypes from "prop-types";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Card, CardStyles, Button, Fonts, List, FinalFormField, ColorPalette } from "yuka";
import styled from "styled-components";

import { useInfiniteFetch, useWrite, DataTypes, QUERY_KEYS } from "api";
import { useFormValue } from "utils/hooks/form";
import useFileDragAndDrop from "utils/hooks/form/useFileDragAndDrop";
import UploadedFileListItem from "./UploadedFileListItem";

const StyledUploadFilesCard = styled(Card)`
  height: 380px; // height perscribed by design
`;

const StyledInput = styled(FinalFormField)`
  display: none;
`;

const StyledDropzone = styled.div`
  height: 100%;
  display: flex;
  border: 1px dashed ${ColorPalette.white30};
  align-items: center;
  justify-content: center;
  margin: 0 16px;
`;

const StyledBrowseFilesButton = styled(Button)`
  margin-top: auto;
  align-self: flex-end;
  margin-right: 16px;
`;

const UploadDocumentsCard = ({ onboardingRequestId }) => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { data: documents } = useInfiniteFetch(
    QUERY_KEYS.ONBOARDING_DOCUMENTS.list(onboardingRequestId, { token: searchParams.get("token") })
  );

  const { onSubmit } = useWrite(
    QUERY_KEYS.ONBOARDING_DOCUMENTS.list(onboardingRequestId, {
      token: searchParams.get("token"),
    })
  );

  const iraStatus = useFormValue("iraStatus", null);
  if (iraStatus) {
    navigate(`/ira/`);
  }

  const handleUpload = files => {
    // Spread files to get an array rather than FileList
    return Promise.all(
      [...files].map(file => onSubmit({ apiType: DataTypes.EXPIRABLE_DOCUMENT, file }))
    );
  };

  const [containerRef, fileInputRef, isDragging] = useFileDragAndDrop({
    multiple: true,
    onChange: handleUpload,
  });

  return (
    <StyledUploadFilesCard
      title="Uploaded files"
      ref={containerRef}
      cardStyle={CardStyles.SECTIONED}
    >
      <StyledInput
        type="file"
        ref={fileInputRef}
        name="files"
        id="files"
        multiple
        onChange={handleUpload}
      />
      {isDragging ? (
        <StyledDropzone>Drop files here to upload</StyledDropzone>
      ) : (
        <>
          {documents?.length > 0 ? (
            <List divider>
              {documents.map(document => (
                <UploadedFileListItem
                  key={document.name}
                  onboardingRequestId={onboardingRequestId}
                  document={document}
                />
              ))}
            </List>
          ) : (
            <StyledDropzone>
              <Fonts.Body1theme50>You can drag-and-drop files here</Fonts.Body1theme50>
            </StyledDropzone>
          )}
          <StyledBrowseFilesButton onClick={() => document.getElementById("files").click()}>
            Browse Files to Upload
          </StyledBrowseFilesButton>
        </>
      )}
    </StyledUploadFilesCard>
  );
};

UploadDocumentsCard.propTypes = {
  onboardingRequestId: PropTypes.string.isRequired,
};

export default UploadDocumentsCard;
