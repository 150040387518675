import { ComponentGroup, FinalFormField } from "yuka";

import { required, isEmail } from "utils/form/inputValidations";

const PersonalInformationFieldSet = () => (
  <ComponentGroup>
    <FinalFormField
      name="name"
      label="Full Name*"
      placeholder="Enter your full name"
      validate={[required]}
    />
    <FinalFormField
      name="email"
      label="Email*"
      placeholder="Please enter your email"
      validate={[required, isEmail]}
    />
  </ComponentGroup>
);

export default PersonalInformationFieldSet;
