import { Fonts } from "yuka";
import styled from "styled-components";
import { DateTime } from "luxon";

const P = styled(Fonts.Body1theme80).attrs({ as: "p" })`
  margin-bottom: 16px;
`;
const Li = styled(Fonts.Body1theme80).attrs({ as: "li" })`
  margin-bottom: 16px;
`;
const U = styled.span`
  text-decoration: underline;
`;
const I = styled.span`
  font-style: italic;
`;

const SPVNDA = () => (
  <>
    <P>NONDISCLOSURE AGREEMENT</P>
    <P>
      This NONDISCLOSURE AGREEMENT (the “Agreement”) is made as of {DateTime.now().toLocaleString()}
      , by and between you (the "Recipient") and the manager of the fund you are subscribing to,
      together with its affiliates (“Discloser”). Discloser has disclosed and/or may further
      disclose its Confidential Information (as defined below) to Recipient in connection with a
      potential opportunity to purchase equity interests in Discloser and the underlying securities
      represented by those equity interests (the “Opportunity”) pursuant to the terms and conditions
      of this Agreement. In this Agreement, Discloser and Recipient are also each, a “Party,” and
      together, the “Parties.” In consideration of Recipient receiving the Confidential Information
      of Discloser, Recipient agrees as follows:
    </P>
    <ol>
      <li>
        <P>
          <U>Confidential Information.</U> “Confidential Information” means all information (however
          communicated, recorded or preserved) relating to Discloser, its Representatives (as
          defined below), and/or the Opportunity that is disclosed or made available to Recipient
          (in any form or medium), directly or indirectly, by or on behalf of Discloser to Recipient
          or its Representatives, including all information regarding the existence of this
          Opportunity and the relationship of the Parties.
        </P>
        <P>
          For the purposes of this Agreement, "Representatives" shall include a Party’s affiliates,
          and its and its affiliates’ respective directors, officers, employees, contractors,
          registered representatives, legal counsel, accountants, auditors and other representatives
          and advisors (including, without limitation, financial advisors and consultants) provided,
          that, such person receives Confidential Information in the course of the Opportunity.
        </P>
      </li>
      <li>
        <P>
          <U>Use of Confidential Information.</U>
        </P>
        <ol type="a">
          <Li>
            Recipient shall not: (i) discuss, disclose, publish, use or disseminate any Confidential
            Information received or accessed under this Agreement without Discloser’s prior written
            permission, or (ii) mention Discloser, its Representatives, or the Opportunity or
            disclose the Parties’ relationship, including without limitation, in marketing
            materials, presentations, press releases, websites or interviews.
          </Li>
          <Li>
            Recipient may disclose Confidential Information: (i) to Representatives who have a need
            to know, are directly involved in the Opportunity and have a written agreement with
            Recipient requiring them to treat Discloser’s Confidential Information in accordance
            with this NDA, or (ii) to the extent required by law; provided, however, Recipient will
            give Discloser prompt notice to allow Discloser a reasonable opportunity to (x) contest
            and assist in opposing any requirement of disclosure of its Confidential Information;
            (y) seek judicial protection against the disclosure; and (z) have such required
            disclosure be made under a protective order.
          </Li>
          <Li>
            Recipient agrees to be responsible for all use of Discloser’s Confidential Information
            by its Representatives and shall be liable for any breach of this Agreement caused,
            directly or indirectly, by its Representatives.
          </Li>
          <Li>
            Recipient may request Discloser or its Representatives to forward or send Confidential
            Information directly to Recipient&#39;s Representatives, or Discloser or its
            Representatives may do so in the course of the Opportunity. For the avoidance of doubt,
            the terms of this Agreement shall apply to any such Confidential Information disclosed
            by Discloser (or any of Discloser&#39;s Representatives) to any of the Representatives
            of Recipient, as if disclosed directly to Recipient and further disclosed by Recipient
            to its Representative in compliance with this Agreement.
          </Li>
        </ol>
      </li>
      <Li>
        <U>Reporting Unauthorized Disclosure.</U> Recipient shall immediately inform Discloser of
        any breach of the provisions of this Agreement by Recipient or its Representatives or any
        unauthorized disclosure, misappropriation or misuse by any person of any Confidential
        Information, in either case upon Recipient having knowledge of the same.
      </Li>
      <Li>
        <U>Term and Termination.</U> Except for Sections 5 and 6, which shall survive indefinitely,
        and any provisions which may reasonably be interpreted as surviving expiration or
        termination, this Agreement shall remain in effect until its termination by another written
        agreement that specifically references this Agreement.
      </Li>
      <Li>
        <U>Injunctive Relief.</U> Recipient acknowledges that the Confidential Information is of a
        unique character and agrees that its actual or anticipated breach of this Agreement may
        irreparably harm Discloser, which harm may not be adequately compensated with monetary
        damages. Therefore, in the event of Recipient&#39;s (or its Representative&#39;s) actual or
        reasonably anticipated breach, Discloser shall be entitled to seek specific performance of
        this Agreement and to seek an injunction or other equitable relief as may be deemed proper
        by a court without proof of actual damage or posting of a bond. The foregoing remedies shall
        be in addition to other rights and remedies available to the non-breaching Party. The
        Parties acknowledge and agree that the covenants contained herein are necessary for the
        protection of legitimate business interests of each Party and are reasonable in scope and
        content.
      </Li>
      <li>
        <P>
          <U>Miscellaneous.</U>
        </P>
        <ol type="a">
          <Li>
            <I>No Rights Granted.</I> Nothing in this Agreement shall be construed as requiring
            Discloser to disclose Confidential Information, perform any work for, or enter into any
            business engagement or other agreement with Recipient or its Representatives. Nothing in
            this Agreement requires the Parties to proceed with the Opportunity or any transaction
            in connection with which the Confidential Information may be disclosed.
          </Li>
          <Li>
            <I>Due Diligence.</I> Recipient acknowledges and agrees that Discloser will not be
            conducting due diligence on the issuer of the underlying securities, and Recipient shall
            not rely on Discloser to perform any such due diligence.
          </Li>
          <Li>
            <I>Successors and Assigns.</I> The terms and conditions of this Agreement shall inure to
            the benefit of and be binding upon the respective successors and assigns of the Parties,
            provided that Confidential Information of Discloser may not be assigned without the
            prior written consent of Discloser, unless the assignee shall be the successor entity to
            the assignor upon the dissolution of the assignor in its present form. Nothing in this
            Agreement, express or implied, is intended to confer upon any party other than the
            Parties hereto or their respective successors and assigns any rights, remedies,
            obligations, or liabilities under or by reason of this Agreement, except as expressly
            provided in this Agreement.
          </Li>
          <Li>
            <I>Severability.</I> If one or more provisions of this Agreement are held to be
            unenforceable under applicable law, the Parties agree to renegotiate such provision in
            good faith. In the event that the Parties cannot reach a mutually agreeable and
            enforceable replacement for such provision, then (a) such provision shall be excluded
            from this Agreement, (b) the balance of the Agreement shall be interpreted as if such
            provision were so excluded, and (c) the balance of the Agreement shall be enforceable in
            accordance with its terms.
          </Li>
          <Li>
            <I>Governing Law; Jurisdiction.</I> This Agreement and all acts and transactions
            pursuant hereto and the rights and obligations of the Parties hereto shall be governed,
            construed and interpreted in accordance with the laws of the State of New York, without
            giving effect to principles of conflicts of law. Each of the Parties hereto consents to
            the exclusive jurisdiction and venue of the courts of New York, New York.
          </Li>
          <Li>
            <I>Amendment and Waiver.</I> Any term of this Agreement may be amended with the written
            consent of both Parties. Any amendment or waiver effected in accordance with this
            Section shall be binding upon the Parties and their respective successors and assigns.
            Failure to enforce any provision of this Agreement by a party shall not constitute a
            waiver of any term hereof by such party.
          </Li>
          <Li>
            <I>Entire Agreement.</I> This Agreement is the product of both of the Parties hereto,
            and constitutes the entire agreement between such Parties pertaining to the subject
            matter hereof, and merges all prior negotiations and drafts of the Parties with regard
            to the transactions contemplated herein. Any and all other written or oral agreements
            existing between the Parties hereto regarding such transactions are expressly canceled.
          </Li>
          <Li>
            <I>No Publicity.</I> Recipient shall not, without the prior consent of Discloser,
            disclose to any other person, other than as provided in Section 2(b) herein, the
            existence, contents or subject matter of this Agreement, that discussions or
            negotiations are taking place between the Parties or any of the terms, conditions,
            status or other facts with respect thereto.
          </Li>
          <Li>
            <I>Counterparts.</I> This Agreement may be executed in two or more counterparts, each of
            which shall be deemed an original and all of which together shall constitute one
            instrument.
          </Li>
        </ol>
      </li>
    </ol>
  </>
);

export default SPVNDA;
