import { createRoot } from "react-dom/client";

import { setAxiosDefaults } from "api";

import DocumentsApp from "./component";

document.addEventListener("DOMContentLoaded", () => {
  setAxiosDefaults();
  const container = document.getElementById("application");
  const root = createRoot(container);
  root.render(<DocumentsApp />);
});
