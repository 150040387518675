import React from "react";
import { useSearchParams, useParams } from "react-router-dom";
import styled from "styled-components";
import { PageSection } from "yuka";

import { DataTypes, QUERY_KEYS, useFetch, useWrite } from "api";
import { findKey } from "utils";
import {
  FrontendError,
  Loading,
  ExpiredRequestPage,
  InvalidTokenPage,
  NewOnboardingRequestSent,
} from "templates/ErrorPages";

import ZSOnboardingWizard from "./ZSOnboardingWizard";
import ZPFOnboardingWizard from "./ZPFOnboardingWizard";

const Layout = styled(PageSection)`
  flex-direction: row;
  height: 100vh;
  width: 100%;
`;

const ClientOnboardingView = () => {
  const { id } = useParams();
  const [searchParams] = useSearchParams();

  const queryParams = {
    token: searchParams.get("token"),
  };
  const {
    isErrorOnLookup,
    isLoadingLookup,
    data: lookup,
    relationships,
  } = useFetch(QUERY_KEYS.ONBOARDING_LOOKUP.detail(id, [], queryParams));

  const queryKeyBase = React.useMemo(() => {
    const key = findKey(DataTypes, type => type === lookup?.dataType) || "ONBOARDING_REQUEST";
    return QUERY_KEYS[key];
  }, [lookup]);

  const {
    isErrorOnObj,
    isLoadingObj,
    data: obj,
  } = useFetch(queryKeyBase.detail(id, [], queryParams), {
    enabled: Boolean(lookup) && lookup.isActiveToken,
  });

  const { onSubmit: resendRequest } = useWrite(
    queryKeyBase.detail(id, ["resend"], {
      token: searchParams.get("token"),
    }),
    { message: "Onboarding request resent" }
  );

  let errorPage;

  if (isLoadingLookup || (lookup && isLoadingObj)) {
    errorPage = <Loading />;
  } else if (isErrorOnLookup || (lookup && isErrorOnObj)) {
    errorPage = <FrontendError />;
  } else if (lookup) {
    if (!lookup.isActiveToken && Date.parse(lookup.expiresOn) > new Date()) {
      errorPage = <NewOnboardingRequestSent />;
    } else if (Date.parse(lookup.expiresOn) < new Date()) {
      errorPage = <ExpiredRequestPage resendOnboardingRequest={resendRequest} />;
    } else if (!lookup.isActiveToken) {
      errorPage = <InvalidTokenPage />;
    }
  }

  if (errorPage) {
    return <Layout>{errorPage}</Layout>;
  }

  switch (obj?.apiType) {
    case DataTypes.ONBOARDING_REQUEST:
      return <ZSOnboardingWizard onboardingRequest={obj} />;
    case DataTypes.SPV_INVITE:
      return <ZPFOnboardingWizard spvInvite={obj} relationships={relationships} />;
    default:
      return null;
  }
};

export default ClientOnboardingView;
