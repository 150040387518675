import React from "react";
import PropTypes from "prop-types";

import { StyledHeadline2 } from "yuka";

import { RadioGroup } from "forms/FormComponents";
import { required } from "utils/form/inputValidations";

import {
  BUYER_AGREEMENT,
  BUYER_FIRM_ORDER,
  SELLER_AGREEMENT,
  SELLER_FIRM_ORDER,
  regulatoryLocationOptions,
} from "./constants";

const RegulatoryLocation = ({ onboardingRequest }) => {
  let headlineText = "Where is the client located?";
  if (
    onboardingRequest.engagementType === BUYER_AGREEMENT ||
    onboardingRequest.engagementType === BUYER_FIRM_ORDER
  ) {
    headlineText = "Where is the buyer located?";
  } else if (
    onboardingRequest.engagementType === SELLER_AGREEMENT ||
    onboardingRequest.engagementType === SELLER_FIRM_ORDER
  ) {
    headlineText = "Where is the seller located?";
  } else if (onboardingRequest.spvInvite) {
    headlineText = "Where is the investor located?";
  }
  return (
    <>
      <StyledHeadline2>{headlineText}</StyledHeadline2>
      <RadioGroup
        items={regulatoryLocationOptions}
        name="regulatoryLocation"
        validate={[required]}
      />
    </>
  );
};

RegulatoryLocation.propTypes = {
  onboardingRequest: PropTypes.shape({
    engagementType: PropTypes.string,
    spvInvite: PropTypes.shape({
      apidId: PropTypes.string,
    }),
  }),
};

export default RegulatoryLocation;
