import React from "react";
import styled from "styled-components";

import { ComponentGroup, StyledHeadline2 } from "yuka";

import { Checkbox, InputRow } from "forms/FormComponents";
import { useFormValue } from "utils/hooks/form";

import {
  ENTITY_SOURCES,
  TRUST_SOURCES,
  US,
  EU,
  FOREIGN_OTHER,
  SEC_REGISTER_ENTITY,
  NON_REGISTERED_US_LLC_OR_LP,
  INDIVIDUAL_SOURCES,
} from "./constants";
import RequiredDocumentsListItem from "./RequiredDocumentsListItem";

const StyledInputRow = styled(InputRow)`
  margin-left: 44px;
`;

const RequiredDocumentsList = () => {
  const regulatoryLocation = useFormValue("regulatoryLocation");
  const sourceType = useFormValue("sourceType");
  const entityType = useFormValue("entityType");

  let wForm = "";
  if (regulatoryLocation === US) {
    wForm = "W9";
  } else {
    if (sourceType === ENTITY_SOURCES) {
      wForm = "W8-BEN-E";
    } else {
      wForm = "W8-BEN";
    }
  }
  let clientType = "";
  let governmentIdHelpText = "";
  let wFormHelpText = "";
  let wFormButtonText = "";
  if (sourceType === INDIVIDUAL_SOURCES) {
    clientType = regulatoryLocation === US ? "US Individual" : "Non-US Individual";
    governmentIdHelpText = "Please upload a photo of the front page of your gov't issued ID";
    wFormHelpText = `Please upload your executed ${wForm} or click the button below if you would like to complete one on DocuSign`;
    wFormButtonText = `Please include a ${wForm} for me to complete on DocuSign`;
  } else if (sourceType === ENTITY_SOURCES) {
    if (regulatoryLocation === US) {
      clientType = entityType === SEC_REGISTER_ENTITY ? "an SEC Registered Entity" : "a US Entity";
    } else {
      clientType = "Non-US Entity";
    }
    governmentIdHelpText =
      "Please upload a photo of the front page of a gov't issued ID for the authorized signatory(ies) for the Entity";
    wFormHelpText = `Please upload an executed ${wForm} for the Entity or click the button below to complete one on DocuSign`;
    wFormButtonText = `Please include a ${wForm} for me to complete on DocuSign`;
  } else if (sourceType === TRUST_SOURCES) {
    clientType = "Trust";
    governmentIdHelpText =
      "Please upload a photo of the front page of the gov't issued ID(s) for the Trust's Trustee(s)";
    wFormHelpText = `Please upload an executed ${wForm} for the Trustee(s) if you have one on file or click the button below if you would like to complete one on DocuSign`;
    wFormButtonText = `Please include a ${wForm} for the Trustee(s) to complete on DocuSign`;
  }
  return (
    <>
      <ComponentGroup>
        <StyledHeadline2>Required KYC for {clientType}:</StyledHeadline2>
        {
          <RequiredDocumentsListItem
            documentDescription="Government ID of Authorized Signatory"
            number={1}
            helpText={governmentIdHelpText}
          />
        }
        {
          <RequiredDocumentsListItem
            documentDescription={wForm}
            number={2}
            helpText={wFormHelpText}
            needsWForm
            wFormButtonText={wFormButtonText}
          />
        }

        {regulatoryLocation === US && sourceType === ENTITY_SOURCES && (
          <>
            {entityType === SEC_REGISTER_ENTITY && (
              <RequiredDocumentsListItem
                documentDescription="Form ADV"
                number={3}
                helpText="Please upload your Form ADV"
              />
            )}
            {entityType === NON_REGISTERED_US_LLC_OR_LP && (
              <>
                {
                  <RequiredDocumentsListItem
                    documentDescription="Formation Document or Equivalent"
                    number={3}
                    helpText="Please upload a Certificate of Formation or an equivalent document for the entity"
                  />
                }
                {
                  <RequiredDocumentsListItem
                    documentDescription="Operating Agreement"
                    number={4}
                    helpText="Please upload the entity's governing document (e.g., Limited Liability Company Agreement or Limited Partnership Agreement or Articles of Incorporation)"
                  />
                }
                {
                  <RequiredDocumentsListItem
                    documentDescription="Certificate of Good Standing (if entity over 3 years old)"
                    number={5}
                    helpText="Please upload a Certificate of Good Standing for the Entity, if you have one"
                  />
                }
              </>
            )}
          </>
        )}

        {regulatoryLocation === EU && sourceType === ENTITY_SOURCES && (
          <>
            {
              <RequiredDocumentsListItem
                documentDescription="Formation Document or Equivalent"
                number={3}
              />
            }
            {
              <RequiredDocumentsListItem
                documentDescription="Operating Agreement or Articles of Incorporation"
                number={4}
              />
            }
            {
              <RequiredDocumentsListItem
                documentDescription="Certificate of Good Standing (if entity over 3 years old)"
                number={5}
              />
            }
          </>
        )}

        {regulatoryLocation === FOREIGN_OTHER && sourceType === ENTITY_SOURCES && (
          <>
            {
              <RequiredDocumentsListItem
                documentDescription="Formation Document or Equivalent"
                number={3}
              />
            }
            {
              <RequiredDocumentsListItem
                documentDescription="Operating Agreement or Articles of Incorporation"
                number={4}
              />
            }
            {
              <RequiredDocumentsListItem
                documentDescription="Certificate of Good Standing (if entity over 3 years old)"
                number={5}
              />
            }
          </>
        )}
      </ComponentGroup>
      {sourceType === TRUST_SOURCES && (
        <ComponentGroup>
          <StyledHeadline2>
            Please upload a copy of the trust or excerpts evidencing:
          </StyledHeadline2>
          {
            <RequiredDocumentsListItem
              documentDescription="The Trust's formation and expiration dates"
              number={3}
            />
          }
          {
            <RequiredDocumentsListItem
              documentDescription="The identity of the Trustees and Beneficiaries"
              number={4}
            />
          }
          {
            <RequiredDocumentsListItem
              documentDescription="Confirmation that the Trust may purchase private securities"
              number={5}
            />
          }
          <StyledInputRow>
            <Checkbox
              name="trustAttestation"
              helpText="I attest that the trust can make high risk investments including purchasing venture-backed private securities"
            />
          </StyledInputRow>
        </ComponentGroup>
      )}
    </>
  );
};

export default RequiredDocumentsList;
