import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import {
  FileEmptyIcon,
  FontColors,
  HyperLink,
  LinkTypes,
  List,
  ListItem,
  StyledBody1,
  StyledHeadline1,
  StyledHeadline2,
} from "yuka";

import { useFormValue } from "utils/hooks/form";
import LoadingSpinner from "utils/LoadingSpinner";

import {
  BUYER_AGREEMENT,
  SELLER_AGREEMENT,
  BUYER_FIRM_ORDER,
  SELLER_FIRM_ORDER,
  ENTITY_SOURCES,
  US,
  INDIVIDUAL_SOURCES,
} from "./constants";

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 740px;
`;

const StyledFlexBox = styled.div`
  display: flex;
  gap: 12px;
`;

const StyledDownloadSpan = styled(StyledBody1)`
  ${FontColors.branding};
`;

const StyledList = styled(List)`
  height: fit-content;
`;

const ReviewAndSign = ({ onboardingRequest, isDocuSignLoading }) => {
  const sourceType = useFormValue("sourceType");
  const regulatoryLocation = useFormValue("regulatoryLocation", false);
  const needsWForm = useFormValue("needsWForm", false);
  const authorizedSignatoryEmail = useFormValue("authorizedSignatoryEmail", false);
  return (
    <StyledContainer>
      {onboardingRequest.requiresDocusign && (
        <StyledHeadline2>
          {authorizedSignatoryEmail &&
          sourceType !== INDIVIDUAL_SOURCES &&
          !onboardingRequest.needsKyc
            ? "The following items will be sent to the authorized signatory via DocuSign"
            : "You will complete the following items on Docusign:"}
        </StyledHeadline2>
      )}
      <StyledList>
        {(onboardingRequest.engagementType === BUYER_AGREEMENT ||
          onboardingRequest.engagementType === SELLER_AGREEMENT) &&
          !authorizedSignatoryEmail && (
            <ListItem
              leadingIcon={FileEmptyIcon}
              text={
                <StyledHeadline1>
                  {onboardingRequest.engagementType === BUYER_AGREEMENT
                    ? "Buyer Agreement"
                    : "Seller Agreement"}
                </StyledHeadline1>
              }
            />
          )}
        {(onboardingRequest.engagementType === BUYER_FIRM_ORDER ||
          onboardingRequest.engagementType === SELLER_FIRM_ORDER) &&
          !authorizedSignatoryEmail && (
            <ListItem
              leadingIcon={FileEmptyIcon}
              text={
                <StyledHeadline1>
                  {onboardingRequest.engagementType === BUYER_FIRM_ORDER
                    ? "Buyer Firm Order"
                    : "Seller Firm Order"}
                </StyledHeadline1>
              }
            />
          )}
        {needsWForm && (
          <ListItem
            leadingIcon={FileEmptyIcon}
            text={
              <StyledHeadline1>
                {regulatoryLocation === US
                  ? "W9"
                  : sourceType === ENTITY_SOURCES
                  ? "W8-BEN-E"
                  : "W8-BEN"}
              </StyledHeadline1>
            }
          />
        )}
        {(onboardingRequest.sendBuysideQuestionnaire ||
          onboardingRequest.sendSellsideQuestionnaire ||
          onboardingRequest.sendSpvQuestionnaire) && (
          <ListItem
            leadingIcon={FileEmptyIcon}
            text={<StyledHeadline1>Investor Questionnaire</StyledHeadline1>}
          />
        )}
        {onboardingRequest.spvInvite && (
          <ListItem
            leadingIcon={FileEmptyIcon}
            text={<StyledHeadline1>Signature pages</StyledHeadline1>}
          />
        )}
        {onboardingRequest.sendWireInstructions && (
          <ListItem
            leadingIcon={FileEmptyIcon}
            text={<StyledHeadline1>Wire Instructions</StyledHeadline1>}
          />
        )}
      </StyledList>
      {sourceType === INDIVIDUAL_SOURCES && (
        <>
          <StyledHeadline2>
            Please review and save the following documents in your records:
          </StyledHeadline2>
          <StyledList>
            <ListItem
              leadingIcon={FileEmptyIcon}
              text={
                <StyledFlexBox>
                  <StyledHeadline1>RegBI</StyledHeadline1>
                  <HyperLink
                    linkType={LinkTypes.DOWNLOAD}
                    url="https://www.zanbato.com/static/docs/regbi.pdf"
                  >
                    <StyledDownloadSpan>(download)</StyledDownloadSpan>
                  </HyperLink>
                </StyledFlexBox>
              }
            />
            <ListItem
              leadingIcon={FileEmptyIcon}
              text={
                <StyledFlexBox>
                  <StyledHeadline1>Form CRS</StyledHeadline1>
                  <HyperLink
                    linkType={LinkTypes.DOWNLOAD}
                    url="https://www.zanbato.com/static/docs/formcrs.pdf"
                  >
                    <StyledDownloadSpan>(download)</StyledDownloadSpan>
                  </HyperLink>
                </StyledFlexBox>
              }
            />
          </StyledList>
        </>
      )}
      {isDocuSignLoading && <LoadingSpinner />}
    </StyledContainer>
  );
};

ReviewAndSign.propTypes = {
  onboardingRequest: PropTypes.shape({
    engagementType: PropTypes.string,
    needsKyc: PropTypes.bool,
    needsSignedNda: PropTypes.bool,
    sendBuysideQuestionnaire: PropTypes.bool,
    sendSellsideQuestionnaire: PropTypes.bool,
    sendWireInstructions: PropTypes.bool,
    sendSpvQuestionnaire: PropTypes.bool,
    spvInvite: PropTypes.array,
    requiresDocusign: PropTypes.bool,
  }),
  isDocuSignLoading: PropTypes.bool.isRequired,
};

ReviewAndSign.defaultProps = {
  engagementType: "",
};

export default ReviewAndSign;
