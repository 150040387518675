import { StyledHeadline2, ComponentGroup } from "yuka";

import { RadioGroup } from "forms/FormComponents";
import { requiredBoolean } from "utils/form/inputValidations";
import { useFormValue } from "utils/hooks/form";

import { INDIVIDUAL_SOURCES } from "./constants";

const IRAStatusOptions = [
  { label: "Yes", value: true },
  { label: "No", value: false },
];

const IRAStatus = () => {
  const sourceType = useFormValue("sourceType");
  const headlineText =
    sourceType === INDIVIDUAL_SOURCES
      ? "Are you investing through an IRA?"
      : "Is the investor investing through an Employee Benefit Plan or an ERISA member?";
  return (
    <ComponentGroup>
      <StyledHeadline2>{headlineText}</StyledHeadline2>
      <RadioGroup items={IRAStatusOptions} name="iraStatus" validate={[requiredBoolean]} />
    </ComponentGroup>
  );
};

export default IRAStatus;
