import React from "react";
import PropTypes from "prop-types";
import { useSearchParams } from "react-router-dom";
import { Form } from "react-final-form";
import { StandardWizardActions } from "yuka";

import { FinalFormWizard } from "xeYuka";
import { QUERY_KEYS, DataTypes, getRelatedId, useWrite } from "api";
import { compact } from "utils";

import { INDIVIDUAL_SOURCES } from "./constants";
import SourceType from "./SourceType";
import RegulatoryLocation from "./RegulatoryLocation";
import UploadDocumentsCard from "./UploadDocumentsCard";
import RequiredDocumentsList from "./RequiredDocumentsList";
import AuthorizedSigner from "./AuthorizedSigner";
import ReviewAndSign from "./ReviewAndSign";
import PersonalInformationFieldSet from "./PersonalInformationFieldSet";
import IRAStatus from "./IRAStatus";
import SPVDocuments from "./SPVDocuments";

const ZSOnboardingWizard = ({ onboardingRequest }) => {
  const [searchParams] = useSearchParams();

  const { onSubmit: submitAndFinishOnDocuSign, isLoading: isDocuSignLoading } = useWrite(
    QUERY_KEYS.ONBOARDING_REQUEST.detail(onboardingRequest.apiId, ["finish_on_docusign"], {
      token: searchParams.get("token"),
    })
  );

  const { onSubmit: saveOnIncrementStep } = useWrite(
    QUERY_KEYS.ONBOARDING_REQUEST.detail(
      onboardingRequest.apiId,
      ["client_update_onboarding_request"],
      {
        token: searchParams.get("token"),
      }
    )
  );

  const onAdvance = values => {
    saveOnIncrementStep(values);
  };

  let steps = compact([
    Boolean(onboardingRequest.spvInvite) && {
      name: "Personal Information",
      leftContent: (
        <>
          <PersonalInformationFieldSet />
          <StandardWizardActions />
        </>
      ),
      onAdvance,
    },
    onboardingRequest.needsSignedNda && {
      name: "SPV Documents",
      leftContent: (
        <>
          <SPVDocuments
            spvInviteId={getRelatedId(onboardingRequest.spvInvite)}
            spvInviteToken={onboardingRequest.spvInviteToken}
          />
          <StandardWizardActions />
        </>
      ),
      onAdvance,
    },
    onboardingRequest.needsKyc && {
      name: "Client's Domicile",
      leftContent: (
        <>
          <RegulatoryLocation onboardingRequest={onboardingRequest} />
          <StandardWizardActions />
        </>
      ),
      onAdvance,
    },
    (onboardingRequest.needsKyc || Boolean(onboardingRequest.spvInvite)) && {
      name: "Individual, Entity or Trust",
      leftContent: (
        <>
          <SourceType onboardingRequest={onboardingRequest} />
          <StandardWizardActions />
        </>
      ),
      onAdvance,
    },
    Boolean(onboardingRequest.spvInvite) && {
      name: "IRA/ERISA Status",
      leftContent: (
        <>
          <IRAStatus />
          <StandardWizardActions />
        </>
      ),
      onAdvance,
    },
    onboardingRequest.needsKyc && {
      name: "Upload Documents",
      leftContent: (
        <>
          <RequiredDocumentsList />
          <StandardWizardActions />
        </>
      ),
      rightContent: <UploadDocumentsCard onboardingRequestId={onboardingRequest.apiId} />,
      onAdvance,
    },
    onboardingRequest.sourceType !== INDIVIDUAL_SOURCES && {
      name: "Authorized Signatory",
      leftContent: (
        <>
          <AuthorizedSigner />
          <StandardWizardActions />
        </>
      ),
      onAdvance,
    },
    {
      name: "Review & Sign",
      leftContent: (
        <>
          <ReviewAndSign
            onboardingRequest={onboardingRequest}
            isDocuSignLoading={isDocuSignLoading}
          />
          <StandardWizardActions />
        </>
      ),
    },
  ]);

  const wrappedOnSubmit = (values, ...params) => {
    if (values.sourceType === INDIVIDUAL_SOURCES) {
      delete values.entityName;
    }
    submitAndFinishOnDocuSign(values, ...params).then(response => {
      window.location.href = response.data.redirect_url;
    });
  };

  let initialStep = 1;
  if (onboardingRequest.hasEnvelope && onboardingRequest.needsKyc) {
    initialStep = 3;
  }
  if (onboardingRequest.spvInvite) {
    initialStep += 1;
  }

  return (
    <Form
      onSubmit={wrappedOnSubmit}
      initialValues={{
        apiId: onboardingRequest.apiId,
        apiType: DataTypes.ONBOARDING_REQUEST,
        authorizedSigner: onboardingRequest.authorizedSignatoryEmail === null,
        entityName:
          onboardingRequest.sourceType !== INDIVIDUAL_SOURCES ? onboardingRequest.name : "",
        ...onboardingRequest,
      }}
    >
      {() => (
        <FinalFormWizard
          requireDirtyForSubmit={false}
          requireValidForSubmit={false}
          submitText={onboardingRequest.requiresDocusign ? "Finish on DocuSign" : "Finish"}
          initialStep={initialStep}
          title="Onboarding - KYC"
          steps={steps}
        />
      )}
    </Form>
  );
};

ZSOnboardingWizard.propTypes = {
  onboardingRequest: PropTypes.shape({
    hasEnvelope: PropTypes.bool,
    needsKyc: PropTypes.bool,
    apiId: PropTypes.string,
    engagementType: PropTypes.string,
    authorizedSignatoryEmail: PropTypes.string,
    sourceType: PropTypes.string,
    spvInvite: PropTypes.array,
    needsSignedNda: PropTypes.bool,
    spvInviteToken: PropTypes.string,
    requiresDocusign: PropTypes.bool,
    name: PropTypes.string,
  }).isRequired,
};

export default ZSOnboardingWizard;
