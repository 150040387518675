import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { ColorPalette, StyledBody2, StyledCaption2 } from "yuka";

import { Checkbox, InputRow } from "forms/FormComponents";

const StyledHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 4px;
`;

const StyledCircleNumber = styled.div`
  height: 32px;
  width: 32px;
  border-radius: 50%;
  text-align: center;
  line-height: 32px;
  background-color: ${ColorPalette.blue200};
`;

const StyledSubTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 80%;
  margin-left: 48px;
`;

const RequiredDocumentsListItem = ({
  documentDescription,
  number,
  helpText,
  needsWForm,
  wFormButtonText,
}) => (
  <div>
    <StyledHeader>
      <StyledCircleNumber>{number}</StyledCircleNumber>
      <StyledBody2>{documentDescription}</StyledBody2>
    </StyledHeader>
    <StyledSubTextContainer>
      <StyledCaption2>{helpText}</StyledCaption2>
      {needsWForm && (
        <InputRow>
          <Checkbox name="needsWForm" helpText={wFormButtonText} />
        </InputRow>
      )}
    </StyledSubTextContainer>
  </div>
);

RequiredDocumentsListItem.propTypes = {
  documentDescription: PropTypes.string.isRequired,
  number: PropTypes.number.isRequired,
  helpText: PropTypes.string.isRequired,
  needsWForm: PropTypes.bool,
  wFormButtonText: PropTypes.string,
};

RequiredDocumentsListItem.defaultProps = {
  needsWForm: false,
  wFormButtonText: "",
};

export default RequiredDocumentsListItem;
