import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {
  Button,
  DownloadIcon,
  Fonts,
  FileIcon,
  IconButton,
  List,
  ListItem,
  LinkExternalIcon,
} from "yuka";

import { fetchQuery, fetchQueryResponse, useFetch, useInfiniteFetch, QUERY_KEYS } from "api";
import downloadFileResponse from "utils/downloadFileResponse";

const StyledButton = styled(Button)`
  align-self: end;
`;

const SPVDocuments = ({ spvInviteId, spvInviteToken }) => {
  const { data: spvInvite } = useFetch(QUERY_KEYS.SPV_INVITE.detail(spvInviteId));

  const { data: documents } = useInfiniteFetch(
    QUERY_KEYS.SPV_INVITE_DOCUMENTS.list(spvInvite?.apiId, { token: spvInviteToken })
  );

  const download = document => {
    fetchQueryResponse(
      QUERY_KEYS.SPV_INVITE_DOCUMENTS.detail(spvInvite.apiId, document.apiId, ["download"], {
        token: spvInviteToken,
      })
    ).then(data => {
      const documentName =
        document.extension === document.name.split(".").at(-1)
          ? document.name
          : `${document.name}.${document.extension}`;
      downloadFileResponse(data, documentName);
    });
  };

  const downloadButton = document => (
    <IconButton onClick={() => download(document)} icon={LinkExternalIcon} />
  );

  const downloadAll = () => {
    fetchQuery(
      QUERY_KEYS.SPV_INVITE_DOCUMENTS.list(spvInvite.apiId, { token: spvInviteToken }, [
        "download_all",
      ]),
      { rawData: true }
    ).then(queryData => {
      var element = document.createElement("a");
      element.setAttribute("href", "data:text/plain;base64," + queryData);
      element.setAttribute("download", `${spvInvite.spvFullName} Documents.zip`);
      element.style.display = "none";
      document.body.appendChild(element);
      element.click();
    });
  };

  return (
    <>
      <Fonts.Headline2theme80>
        To subscribe to {spvInvite?.spvFullName} ({spvInvite?.companyLegalName})
      </Fonts.Headline2theme80>
      <Fonts.Headline2theme80>
        Review the following SPV documents before onboarding:
      </Fonts.Headline2theme80>
      <List divider>
        {documents?.map(document => (
          <ListItem
            key={document.name}
            text={document.name}
            leadingIcon={FileIcon}
            trailingContent={downloadButton(document)}
          />
        ))}
      </List>
      <StyledButton leadingIcon={DownloadIcon} onClick={downloadAll}>
        Download All
      </StyledButton>
    </>
  );
};

SPVDocuments.propTypes = {
  spvInviteId: PropTypes.string.isRequired,
  spvInviteToken: PropTypes.string.isRequired,
};

export default SPVDocuments;
